.card {
  &.stat {
    border: none;
    border-radius: 0;
    box-shadow: 0px 3px 6px rgba(0, 0, 0, 0.16);
    border-left: 11px solid;
    padding: 12px 20px;
    .stat-header {
      text-align: center;
      margin-bottom: 15px;
      color: var(--bs-body-color);
      .stat-title {
        color: inherit;
      }
      .stat-subtitle {
        font-size: 12px;
      }
    }
    .stat-etat {
      display: flex;
      justify-content: space-around;
      align-items: center;
      padding-top: 15px;
      span {
        font-size: 15px;
        flex: 1;
        text-align: center;
      }
      button i {
        font-size: 25px;
      }
    }
    &.retard-item {
      .stat-etat span {
        color: #a71717;
        font-weight: bold;
      }
    }
  }
  &.task {
    border: 1px solid #b5b6bb;
    border-radius: 0;
    border-left: 9px solid;
    padding: 12px 20px;
    display: flex;
    justify-content: space-between;
    margin: 0;
    flex-direction: column;
    @include media-breakpoint-up(sm) {
      flex-direction: row;
    }
    .task-data {
      color: #707070;
    }
    .task-name {
      flex: 2;
      span {
        font-size: 14px;
        font-style: italic;
      }
    }
    .task-title {
      flex: 1;
    }
    .task-date {
      @include media-breakpoint-up(sm) {
        flex: 0;
        text-align: right;
      }
    }
  }
  &.EMAIL {
    border-left-color: $theme-email;
    .cdc-filler {
      background-color: $theme-email !important;
    }
  }
  &.OAV {
    border-left-color: $theme-oav;
    .cdc-filler {
      background-color: $theme-oav !important;
    }
  }
  &.AFFICHE {
    border-left-color: $theme-affiche;
    .cdc-filler {
      background-color: $theme-affiche !important;
    }
  }
  &.PLV {
    border-left-color: $theme-plv;
    .cdc-filler {
      background-color: $theme-plv !important;
    }
  }
  &.MARKETING_DIRECT {
    border-left-color: $theme-marketing;
    .cdc-filler {
      background-color: $theme-marketing !important;
    }
  }
  &.PAGE_G_FR {
    border-left-color: $theme-page_g;
    .cdc-filler {
      background-color: $theme-page_g !important;
    }
  }
  &.RICH_SMS {
    border-left-color: $theme-rich_sms;
    .cdc-filler {
      background-color: $theme-rich_sms !important;
    }
  }
  &.AUTRE {
    border-left-color: $theme-autre;
    .cdc-filler {
      background-color: $theme-autre !important;
    }
  }
}

.list-tasks {
  margin-bottom: 30px;
  .card {
    margin-top: -1px;
  }
}
