.navbar {
  position: sticky;
  top: 0;
  height: 100%;
  max-height: 100vh;
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  justify-content: space-between;
  background-color: $navbar-bkg-color;
  overflow: auto;
  padding: 0;
  flex: 0 0 auto;
  nav {
    display: flex;
    flex-direction: column;
  }
  a {
    color: $navbar-link-color;
    padding: 12px 22px 12px 16px;
    width: 100%;
    display: flex;
    align-items: center;
    &.router-link-active {
      background-color: $primary;
    }
    i {
      margin-right: 10px;
    }
  }
  #logo {
    background-color: #fff;
    margin: 10px;
    padding: 10px;
  }
}

.user-profil {
  color: #fff;
  padding: 6px 16px;
  font-size: 14px;
  height: $navbar-height;
  display: flex;
  align-items: center;
}
