.login {
  height: 100%;
  flex: 1;
  overflow: hidden;
  @if $login-background-type == "image" {
    background-image: $login-bg-picture;
    background-repeat: $login-bg-repeat;
    background-size: $login-bg-size;
    background-position: $login-bg-position;
  }
  @if $login-background-type == "gradient" {
    @include gradient-directional(
      $deg: $login-bg-deg,
      $start-color: $login-bg-color-1,
      $end-color: $login-bg-color-2,
      $start-percent: $login-bg-color-start,
      $end-percent: $login-bg-color-end
    );
  }
  .login-overflow {
    @extend %flexbox;
    @include align-items($login-overflow-alignment);
    @include justify-content($login-overflow-justify);
    width: 100%;
    height: 100%;
    @if $login-overlay-type == "gradient" {
      @include gradient-directional(
        $deg: $login-bg-overlay-deg,
        $start-color: $login-bg-overlay-color-1,
        $end-color: $login-bg-overlay-color-2,
        $start-percent: $login-bg-overlay-color-start,
        $end-percent: $login-bg-overlay-color-end
      );
    } @else if $login-overlay-type == "image" {
      // background-image: $login-overlay-picture;
      background-repeat: $login-overlay-repeat;
      background-size: $login-overlay-size;
      background-position: $login-overlay-position;
    }
    .box {
      @extend %flexbox;
      @include flex-direction($login-box-direction);
      @include justify-content($login-box-justify);
      box-shadow: $login-box-shadow;
      border-radius: $login-box-radius;
      width: $login-box-width;
      color: $login-box-color;
      background-color: $login-box-bg-color;
      border: $login-box-border;
      @if $login-box-position== "center" {
        -webkit-animation-name: fadeInUp;
        animation-name: fadeInUp;
      } @else if $login-box-position== "fullLeft" {
        -webkit-animation-name: fadeInLeft;
        animation-name: fadeInLeft;
      } @else if $login-box-position== "fullRight" {
        -webkit-animation-name: fadeInRight;
        animation-name: fadeInRight;
      }
      @include flex(none);
      .box-header {
        @extend %flexbox;
        @include flex-direction(column);
        padding: $login-box-header-padding;
        border-bottom: none;
      }
      h1 {
        @include flex(1);
        font-size: $login-title-size;
        font-weight: 600;
        text-align: center;
        margin: 0;
      }
      .separator {
        width: $login-separator-width;
        height: $login-separator-height;
        background: $login-separator-color;
        margin: $login-separator-margin;
      }
      .logo-login {
        max-width: $login-logo-max-width;
        padding: 38px;
        @include media-breakpoint-down(xs) {
          margin: $login-mobile-logo-margin;
        }
        @include media-breakpoint-up(sm) {
          margin: $login-desktop-logo-margin;
        }
      }

      .box-content {
        padding: $login-box-content-padding;
        button[type="submit"] {
          font-size: $login-btn-submit-font-size;
          border: $login-btn-submit-border;
          background-color: $login-btn-submit-bg-color;
          border-radius: $login-btn-submit-radius;
          color: $login-btn-submit-color;
          padding: $login-btn-submit-padding;
          margin: $login-btn-submit-margin;
        }
        .forgot-password-block {
          display: flex;
          align-items: $btn-password-alignment;
          justify-content: $btn-password-justify;
        }
        .btn-link {
          color: red;
        }
        p {
          margin-top: 0;
          margin-bottom: 50px;
        }
        .field {
          margin: $login-field-margin;
        }
        label {
          @extend %flexbox;
          @include align-items(center);
          color: $login-label-color;
          font-size: $login-label-size;
          margin: $login-label-margin;
          i {
            font-size: $login-label-icon-size;
            margin: $login-label-icon-margin;
          }
        }
        input,
        textarea,
        select,
        .Select .Select-value {
          font-size: $login-input-size;
          border-width: $login-input-border-width;
          border-style: $login-input-border-style;
          border-color: $login-input-border-color;
          background-color: $login-input-bg-color;
          border-radius: $login-input-radius;
          color: $login-input-color;
          padding: $login-input-padding;
          &::-webkit-input-placeholder {
            color: $login-input-placeholder-color;
          }
          &::-moz-placeholder {
            color: $login-input-placeholder-color;
          }
          &:-ms-input-placeholder {
            color: $login-input-placeholder-color;
          }
          &:-moz-placeholder {
            color: $login-input-placeholder-color;
          }
          &:-webkit-autofill {
            -webkit-box-shadow: 0 0 0px 1000px white inset;
          }
          &:focus {
            border-width: $login-input-focus-border-width;
            border-style: $login-input-focus-border-style;
            border-color: $login-input-focus-border-color;
          }
        }
        input:focus + label {
          color: $login-label-focus-color;
        }
        .field-error + input,
        textarea,
        select,
        .Select .Select-value {
          border-width: $login-input-error-border-width;
          border-style: $login-input-error-border-style;
          border-color: $login-input-error-border-color;
        }
      }
    }
  }
}

.login-message {
  color: $primary;
  text-align: center;
  font-weight: bold;
}
