.calcul {
  background-color: #fff;
  .montant-title {
    font-size: 20px;
    color: $quaternary;
    text-align: center;
    margin-bottom: 26px;
    font-weight: 600;
    strong {
      font-weight: 700;
    }
  }
  > .question-title {
    color: $quaternary;
    text-transform: uppercase;
    padding-top: 14px;
  }
  .question-wrapper {
    margin-bottom: 0 !important;
  }
  .montant  {
    @extend %flexbox;
    @include flex-direction(row);
    @include justify-content(center);
    color: $quaternary;
    line-height: 1;
  }
  .montant-mois {
    font-size: 90px;
  }
  .montant-details {
    @extend %flexbox;
    @include flex-direction(column);
    font-size: 21px;
    padding-top: 10px;
    margin-left: 10px;
    text-align: left;
    .montant-details-mois {
      font-size: 24px;
    }
  }

}
