@charset "UTF-8";

@import "~normalize.css";

@import "./config/vars-bootstrap";

@import "~bootstrap/scss/bootstrap";

@import "./vendor/index", "./config/index", "./modules/index", "./components/index";

@import "./custom/index";