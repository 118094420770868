.dropdown.v-select {
  @include flex(1 1 100%);
  .dropdown-toggle {
    color: $input-color;
    border-radius: $input-border-radius;
    border-width: $input-border-width;
    border-color: $input-border-color;
    background-color: $input-bg;
    transition: all 0.2s ease;
    width: 100%;
    padding: 0;
    .vs__selected-options {
      @include flex-basis(auto);
      padding: 0;
      input[type="search"],
      input[type="search"]:focus {
        margin: 0;
        padding: 14px 0 14px 10px;
        border: none;
        font-size: $input-font-size;
        line-height: 1;
      }
    }
    .selected-tag {
      margin: 0;
      padding: 14px 0 14px 10px;
      border: none;
      font-size: $input-font-size;
      line-height: 1;
      position: absolute;
      top: 0;
      bottom: 0;
    }

    &:focus {
      border-width: $input-focus-width;
      border-color: $input-focus-border-color;
      outline: none;
      + label {
        color: $input-focus-color;
      }
    }
    &:disabled {
      background-color: $input-disabled-bg;
    }
    &::-webkit-input-placeholder {
      color: $input-placeholder-color;
    }
    &::-moz-placeholder {
      color: $input-placeholder-color;
    }
    &:-ms-input-placeholder {
      color: $input-placeholder-color;
    }
    &:-moz-placeholder {
      color: $input-placeholder-color;
    }
  }
}

.date-month-year-small {
  .dropdown.v-select {
    .dropdown-toggle {
      input[type="search"],
      input[type="search"]:focus,
      .selected-tag {
        padding: 8px 0 8px 10px;
      }
    }
  }
}
