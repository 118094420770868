header {
  // background: $header-bg;
  background: rgb(255, 255, 255);
  background-size: cover;
  @extend %flexbox;
  @include align-items(center);
  padding: $vertical-header-padding $horizontal-header-padding;
  // position: sticky;
  // top: 0;
  // z-index: 2;

  @include media-breakpoint-up(sm) {
    padding: $vertical-header-padding-lg $horizontal-header-padding-lg;
    // position: static;
  }

  @include media-breakpoint-up(xl) {
    padding: $vertical-header-padding-xl $horizontal-header-padding-xl;
  }

  .header-title {
    @extend %flexbox;
    @include flex-direction(column);
    @include align-items(center);
    background-color: $header-title-bg;
    color: $header-title-color;
    font-size: $header-title-size;
    font-weight: bold;
    padding: $header-title-padding;
    img {
      margin: 8px 0;
    }
    i {
      margin-right: $header-title-icon-mr;
      font-size: $header-title-icon-size;
    }

    @include media-breakpoint-up(sm) {
      img {
        margin: 0 10px;
      }
      @include flex-direction(row);
      padding: $header-title-padding-lg;
      font-size: $header-title-size-lg;

      i {
        margin-right: $header-title-icon-mr-lg;
        font-size: $header-title-icon-size-lg;
      }
    }
  }
  .header-right {
    display: flex;
    justify-content: flex-end;
    flex: 1;
  }

  .btn-menu {
    color: $btn-menu-color;
    background-color: $btn-menu-bg-color;
    padding: $btn-menu-padding;
    width: $btn-menu-width;
    height: $btn-menu-height;
    @extend %flexbox;
    @include align-items(center);
    @include justify-content(center);

    i {
      font-size: $btn-menu-icon-size;
    }

    @include media-breakpoint-up(lg) {
      display: none;
    }
  }

}

