.followup{
    .filters{
        display: flex;
        margin-top: 2rem;
    }
    .filter{
        display: flex;
        align-items: center;
        margin-bottom: 10px;
        width: 40%;
        margin-right: 2rem;

        @include media-breakpoint-down(md) {
            width: 100%;
        }

        span{
            margin-right: 10px;
        }

        .field{
            margin: 0;
        }
    }
    thead{
        tr{
            th{
                &:first-child{
                    padding:0;
                    min-width: 11px;
                    width: 11px;
                }
                .field-date-interval{
                    flex-direction: column;
                    align-items: flex-start;
                    justify-content: flex-start;
                }
            }
        }
    }
    tbody{
        tr, tr:nth-child(even){
            td:first-child{
                padding:0;
                background-color: $theme-email;
            }
        
            &.EMAIL td:first-child {
                background-color: $theme-email;
            }
            &.OAV td:first-child {
                background-color: $theme-oav;
            }
            &.AFFICHE td:first-child {
                background-color: $theme-affiche;
            }
            &.PLV td:first-child {
                background-color: $theme-plv;
            }
            &.MARKETING_DIRECT td:first-child {
                background-color: $theme-marketing;
            }
            &.PAGE_G_FR td:first-child {
                background-color: $theme-page_g;
            }
            &.RICH_SMS td:first-child {
                background-color: $theme-rich_sms;
            }
            &.AUTRE td:first-child {
                background-color: $theme-autre;
            }
        }

        .late{
            background-color: #E84F1E;
            padding: 0px 3px;
            border-radius: 5px;
            color: white;
        }

        .valid{
            background-color: #34DBBC !important;
            padding: 0px 3px;
            border-radius: 5px;
            color: white;
        }

        .today{
            font-weight: bold;
        }
    } 
}