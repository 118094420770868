$grid-breakpoints: (
  xs: 0,
  sm: 576px,
  md: 768px,
  lg: 992px,
  xl: 1200px,
  xxl: 1440px,
  xxxl: 1600px,
) !default;

$primary: #006a53;
$secondary: #e95c32;
$tertiary: #44a993;
$quaternary: #11594b;

$text-color: #4d4f5c;
$font-icon: "Font Awesome 5 Pro";
$btn-font-size: 18px;

$grid-row-columns: 12;

$global-radius: 6px;

// Notification
$alert-error-color: #f53242;
$alert-warning-color: #f57a32;

$vertical-layout-padding: 20px;
$horizontal-layout-padding: 20px;
$vertical-layout-padding-lg: 24px;
$horizontal-layout-padding-lg: 44px;
$vertical-layout-padding-xl: $vertical-layout-padding-lg;
$horizontal-layout-padding-xl: $horizontal-layout-padding-lg;

$active-color: #35DBBB;

$theme-email: #6250a1;
$theme-marketing: #FF6D80;
$theme-oav: #50BAFC;
$theme-affiche: #FFB845;
$theme-plv: #7bb929;
$theme-autre: #5E7393;
$theme-page_g: #006a53;
$theme-rich_sms: #f3741f;

.flexNoWrap {
  flex-wrap: nowrap !important;
}

.minH-100 {
  min-height: 100%;
}