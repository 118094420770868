.btn {
  i + span,
  span + i {
    margin-left: 10px;
  }
}

.btn-secondary {
  color: #fff;
}

.flex-btn-group {
  @extend %flexbox;
  @include align-items(center);
  width: 100%;

  &.center {
    @include justify-content(center);
  }
  &.right {
    @include justify-content(flex-end);
  }
  &.between {
    @include justify-content(space-between);
  }
  &.around {
    @include justify-content(space-around);
  }
  .btn {
    margin: 0 8px;
    &:first-child {
      margin-left: 0;
    }
    &:last-child {
      margin-right: 0;
    }
  }
  &.column {
    flex-direction: column;
  }
}

.tooltip-btn .tooltip-text {
  visibility: hidden;
  background-color: rgba(#000, 0.6);
  color: #fff;
  font-size: 14px;
  line-height: 16px;
  text-align: center;
  border-radius: 6px;
  position: absolute;
  transform: translateX(-50%);
  top: 100%;
  left: 50%;
  z-index: 1;
  padding: 8px 12px;
  pointer-events: none;
}
.tooltip-btn:hover .tooltip-text {
  visibility: visible;
  display: inline-block;
}
.btn-theme {
  color: #fff;
  &.EMAIL {
    background-color: $theme-email;
  }
  &.RICH_SMS {
    background-color: $theme-oav;
  }
  &.OAV {
    background-color: $theme-oav;
  }
  &.AFFICHE {
    background-color: $theme-affiche;
  }
  &.PLV {
    background-color: $theme-plv;
  }
  &.MARKETING_DIRECT {
    background-color: $theme-marketing;
  }
  &.PAGE_G_FR {
    background-color: $theme-page_g;
  }
  &.RICH_SMS {
    background-color: $theme-rich_sms;
  }
  &.AUTRE {
    background-color: $theme-autre;
  }
}

.buttons-radio {
  display: flex;
  flex: 1;
  flex-wrap: wrap;
  margin: -4px;
  .btn-radio {
    cursor: pointer;
    background-color: #fff;
    border: 1px solid $input-border-color;
    margin: 4px;
    border-radius: 3px;
    padding: 6px 10px;

    &.disabled {
      opacity: 0.6;
      background-color: #ddd;
      cursor: not-allowed;
    }
    &.btn-radio-active {
      color: #fff;
      background-color: $primary;
      border-color: $primary;
    }
  }
}

.glue {
  .buttons-radio {
    margin: 0;
    flex-wrap: nowrap;
    .btn-radio {
      border-radius: 0;
      margin: 0;
      margin-left: -1px;
      &:first-child {
        margin-left: 0px;
        border-radius: 3px 0 0 3px;
      }

      &:last-child {
        border-radius: 0 3px 3px 0;
      }
    }
  }
}

.img-link {
  display: inline-block;
  box-shadow: 0px 3px 6px rgba(0, 0, 0, 0.16);
  max-height: 440px;
  overflow: auto;
}

.btn-blank {
  align-items: center;
  display: flex;
  font-size: 13px;
  i {
    font-size: 26px;
  }
  &.btn-blank-active {
    color: #fff;
    background-color: $primary;
    i {
      font-weight: 900;
      position: relative;
      &:before {
        content: "\F4B0";
      }
      &:after {
        width: 14px;
        height: 14px;
        content: "\f12a";
        border-radius: 100%;
        color: #fff;
        background: #fd8f07;
        position: absolute;
        top: -4px;
        right: -6px;
        display: flex;
        align-items: center;
        justify-content: center;
        font-size: 10px;
        font-weight: 900;
        font-family: "Font Awesome 6 Pro";
        outline: 2px solid $primary;
        box-shadow: 0 1px 3px rgba(#000, 0.9);
      }
    }
  }
}

.btn-explication {
  opacity: 0.6;
  font-size: 14px;
  font-style: italic;
  margin-top: 4px;
}

.btn-radio-tooltip {
  display: none;
  position: absolute;
  bottom: 120%; /* Position au-dessus de la div principale */
  left: 50%;
  transform: translateX(-50%);
  background-color: #333;
  color: #fff;
  text-align: center;
  padding: 5px 10px;
  border-radius: 4px;
  white-space: nowrap;
  box-shadow: 0 2px 5px rgba(0, 0, 0, 0.3);
  z-index: 1000;
}
.btn-radio {
  position: relative;
  display: inline-block;
  cursor: pointer;

  &:hover {
    .btn-radio-tooltip {
      display: block;
    }
  }
}
