.tooltiped {
  position: relative;
  display: flex;
  align-items: center;
  justify-content: center;
  z-index: 2;
  &:hover {
    .tooltip-block {
      display: flex;
    }
  }
}

.tooltip-block {
  display: none;
  position: absolute;
  z-index: 2;
  background: rgba($primary, 1);
  color: #fff;
  font-size: 12px;
  word-break: normal;
  white-space: normal;

  line-height: 1;
  border-radius: 5px;
  padding: 4px 6px;
  box-shadow: 0px 3px 20px rgba(99, 99, 99, 0.17);
  text-transform: none;
  white-space: nowrap;
  align-items: center;
  // max-width: 200px;
  &.bottom {
    top: 100%;
    margin-top: -8px;
    &:before {
      content: '';
      position: absolute;
      left: 0;
      right: 0;
      margin: 0 auto;
      top: -6px;
      width: 0;
      height: 0;
      border-left: 6px solid transparent;
      border-right: 6px solid transparent;
      border-bottom: 6px solid rgba($primary, 1);
    }
  }
  &.right {
    left: 100%;
    margin-left: 6px;
    &:before {
      content: '';
      position: absolute;
      right: 100%;
      width: 0;
      height: 0;
      border-top: 3px solid transparent;
      border-right: 4px solid rgba($primary, 1);
      border-bottom: 3px solid transparent;
    }
  }
}

.statut-inactif {
  .tooltip {
    background-color: #a0a0a0;
    &:before {
      border-right-color: #a0a0a0;
    }
  }
}
