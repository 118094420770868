.commentaires-sidebar {
  display: flex;
    flex-direction: column;
  position: fixed;
  right: 0;
  top: $navbar-height;
  bottom: 0;
  background-color: #fff;
  z-index: 3;
  padding: 12px 12px 0;
  width: 360px;
  box-shadow: 0 1px 2px rgba(0,0,0,0.07), 
                0 2px 4px rgba(0,0,0,0.07), 
                0 4px 8px rgba(0,0,0,0.07), 
                0 8px 16px rgba(0,0,0,0.07),
                0 16px 32px rgba(0,0,0,0.07), 
                0 32px 64px rgba(0,0,0,0.07);
  max-height: 100%;
}
.commentaires-container {
  padding: 0 4px;
  overflow: auto;
  flex: 1;
  &::-webkit-scrollbar {
    width: 4px;
    height: 4px;
  }
  &::-webkit-scrollbar-track {
    background: transparent;
  }
  &::-webkit-scrollbar-thumb {
    border-radius: 12px;
    background: rgba(#000,.2);
    &:hover {
      background: rgba(#000,.4);
    }
  }
  &::-webkit-scrollbar-corner {
    background-color: transparent;
  }    
}
.commentaire {
  display: flex;
  margin-bottom: 10px;
  .commentaire-user {
    display: flex;
    align-items: center;
    justify-content: center;
    width: 44px;
    height: 44px;
    background-color: #ddd;
    border-radius: 100%;
  }
  .commentaire-user + .commentaire-message {
    margin-left: 6px;
  }
  .commentaire-message {
    background-color: fixed;
    border-radius: 20px 20px 20px 0;
    background-color: #eee;
    display: flex;
    flex-direction: column;
    padding: 8px 14px;
    flex: 1;
    font-size: 14px;
  }
  .commentaire-text {
    p {
      &:last-child {
        margin-bottom: 0;
      }
    }
  }
  .commentaire-date {
    font-size: 12px;
    margin-top: 2px;
    opacity: .6;
  }
}

.commentaires-title {
  display: flex;
  justify-content: center;
  align-items: center;
  font-size: 18px;
  margin-bottom: 16px;
  margin-left: 10px;
  i {
    font-size: 30px;
    margin-right: 10px;
  }
}
.btn-close-sidebar {
  cursor: pointer;
  position: absolute;
  transform: translateX(-50%);
  left: 0;
  background-color: #fff;
  width: 30px;
  height: 30px;
  display: flex;
  align-items: center;
  justify-content: center;
  border-radius: 100%;
  box-shadow: 0 1px 2px rgba(0,0,0,0.07), 
  0 2px 4px rgba(0,0,0,0.07), 
  0 4px 8px rgba(0,0,0,0.07), 
  0 8px 16px rgba(0,0,0,0.07);
  &:hover {
    background-color: darken(#fff,2%);
  }
}