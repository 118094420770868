.tabs {
  display: flex;
  flex-wrap: wrap;
  .tab {
    font-size: 18px;
    color: #000;
    background-color: #e9ebeb;
    padding: 8px 14px;
    border-radius: 10px;
    margin: 4px;
    border: 1px solid transparent;
    @include media-breakpoint-up(lg) {
      font-size: 20px;
      padding: 12px 34px;
      margin: 0px -10px;
      border: none;
      &:first-child {
        margin-left: 0;
      }
    }
    &.router-link-active {
      background-color: #fff;
      color: $primary;
      border: 1px dashed #006a53;
      @include media-breakpoint-up(lg) {
        border: none;
        border-radius: 10px 10px 0 0;
        padding: 12px 24px;
        margin: 0;
        z-index: 1;
      }
    }
  }
}
.tab-content {
  flex: 1;
  // max-height: 100%;
  // overflow: auto;
  display: flex;
  flex-direction: column;
}

.tabs-container {
  // flex: 1;
  display: flex;
  flex-direction: column;
  margin-top: 40px;
}

.tabs-nav-wrapper {
  display: flex;
  align-items: center;
  justify-content: space-between;
}