<template>
  <router-link
    :to="{ name: 'Workflow.WorkflowEtape', params: { etape: routeEtape, id: task.support_id } }"
    :class="{ disabled: disabled }"
  >
    <div :class="['card task', typeSupport]">
      <div class="task-data task-name">
        {{ task.nom }}
        <span v-if="task?.date_envoi_souhaitee || task?.etape_dates?.date_envoi_souhaitee">{{
          formatDate(task.date_envoi_souhaitee || task.etape_dates.date_envoi_souhaitee)
        }}</span>
      </div>
      <div class="task-data task-title">{{ renderStatut }}</div>
      <div class="task-data task-date">{{ taskDate || "" }}</div>
    </div>
  </router-link>
</template>
<script>
import { CIBLAGE_STATUTS_TEXT, SUPPORT_STATUT_TEXT } from "../../config/Properties";

import moment from "moment";
import { getRouteEtapeByStatut } from "../router";

export default {
  name: "CardTask",
  props: ["typeSupport", "name", "task", "isCiblage", "disabled"],
  computed: {
    routeEtape() {
      return getRouteEtapeByStatut(this.isCiblage ? this.task.ciblage_statut : this.task.statut);
    },
    renderStatut() {
      return this.isCiblage
        ? CIBLAGE_STATUTS_TEXT[this.task.ciblage_statut]
        : SUPPORT_STATUT_TEXT[this.task.statut][this.typeSupport];
    },
    taskDate() {
      return this.formatDate(this.task.dateTask);
    },
  },
  methods: {
    formatDate(date) {
      return moment(date).format("DD/MM/YYYY");
    },
  },
};
</script>
<style scoped>
.disabled,
.disabled .card {
  pointer-events: none;
  background-color: rgba(0, 0, 0, 0.15);
  border-color: #68665d;
}
</style>
