.steps {
  .step {
    &.validated {
      .icon-block {
        &:before {
          content: "\e90b";
          font-family: 'bigoudenn-status' !important;
          position: absolute;
          top: -18px;
          color: $step-achieve-icon-color;
        }
      }
    }
  }
}