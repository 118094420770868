a,
button {
  background: none;
  border: none;
  border-radius: 0;
  text-decoration: none;
  cursor: pointer;
  transition: all 0.2s ease;
  position: relative;

  &:focus {
    outline: none;
  }
}

// .btn {
//   &.btn-primary {
//     background-color: #fff;
//     border-width: 1px;
//     border-style: solid;
//     border-color: #b2b2b2;
//     color: #000;
//     height: 100%;
//     font-size: 14px;
//     line-height: 18px;
//     padding: 12px 10px;
//     @include justify-content(center);
//     @include align-items(center);
//   }
// }

.btn.disabled,
.btn:disabled {
  opacity: 0.3;
  border-color: transparent;
}
