[type="checkbox"]:not(:checked),
[type="checkbox"]:checked {
  display: none;
}

/* on prépare le label */
[type="checkbox"]:not(:checked) + label,
[type="checkbox"]:checked + label {
  @include flexbox();
  align-items: center;
  position: relative; /* permet de positionner les pseudo-éléments */
  padding-left: $form-checkbox-icon-margin; /* fait un peu d'espace pour notre case à venir */
  cursor: pointer; /* affiche un curseur adapté */
  pointer-events: visible;
}

[type="checkbox"]:not(:checked) + label:before,
[type="checkbox"]:checked + label:before {
  content: "";
  position: absolute;
  left: 0;
  width: $form-checkbox-width;
  height: $form-checkbox-height;
  border-radius: 3px;
  border-color: $form-checkbox-border-color;
  border-width: $form-checkbox-border-width;
  border-style: $form-checkbox-border-style;
  background-color: $form-checkbox-bg-color;
}
[type="checkbox"]:checked + label:before {
  background-color: $form-checkbox-checked-bg-color;
  border-color: $form-checkbox-checked-border-color;
  border-width: $form-checkbox-checked-border-width;
  border-style: $form-checkbox-checked-border-style;
}
[type="checkbox"]:checked + label {
  color: $form-checkbox-checked-label-color !important;
}

/* Aspect général de la coche */
[type="checkbox"]:not(:checked) + label:after,
[type="checkbox"]:checked + label:after {
  content: "\f00c";
  font-family: quote($font-icon);
  position: absolute;
  line-height: $form-checkbox-height;
  left: 2px;
  font-size: $form-checkbox-icon-size;
  font-weight: $form-checkbox-icon-weight;
  color: $form-checkbox-checked-icon-color;
  transition: all 0.2s; /* on prévoit une animation */
}
/* Aspect si "pas cochée" */
[type="checkbox"]:not(:checked) + label:after {
  opacity: 0; /* coche invisible */
  transform: scale(0); /* mise à l'échelle à 0 */
}
/* Aspect si "cochée" */
[type="checkbox"]:checked + label:after {
  opacity: 1; /* coche opaque */
  transform: scale(1); /* mise à l'échelle 1:1 */
}

.checkbox {
  height: 25px;
  width: 25px;
  border: #ced4da solid 1px;
  cursor: pointer;
  color: white;
  font-size: 12px;
  justify-content: center;
  align-items: center;
  display: flex;
  flex-direction: row;
  border-radius: 4px;
  &.checked {
    background-color: $primary;
    border-color: $primary;
    i {
      display: block;
    }
  }
  i {
    display: none;
  }
}
