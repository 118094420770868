.workflow-header {
  .workflow-header-infos {
    padding: 14px;
    border-bottom: 1px solid #D6D6D6;
    @include media-breakpoint-up(lg) {
      padding: 14px 30px;
      border-bottom: 0;
      border-right: 1px solid #D6D6D6;
    }
    &:first-child {
      @include media-breakpoint-up(lg) {
        padding-left: 20px;
      }
    }
    &:last-child {
      border-bottom: none;
      @include media-breakpoint-up(lg) {
        padding-right: 20px;
        border-right: none;
      }
    }
    .support-info-tags {
      margin-top: 16px;
    }
    &.workflow-header-description {
    }
    &.workflow-header-tache {
      text-align: center;
      display: flex;
      flex-direction: column;
      justify-content: space-between;
      
      @include media-breakpoint-up(sm) {
        border-bottom: 0;
        border-right: 1px solid #D6D6D6;
      }
    }
    &.workflow-header-intervenants {
      text-align: center;
      display: flex;
      flex-direction: column;
      align-items: center;
    }
  }
}

.support-info-nom {
  font-size: 18px;
  font-weight: bold;
  color: $primary;
  margin-bottom: 16px;
}
.support-info-contexte {
  font-size: 16px;
}
.support-info-date {
  font-size: 38px;
  color: $active-color;
}
.support-info-type_tache {
  font-size: 19px;
  font-weight: 300;
}
.support-info-volume {
  font-size: 18px;
  font-weight: 500;
  margin-top: 20px;
}
.support-info-numero_adobe {
  font-size: 13px;
}
.support-info-referent {
  font-size: 40px;
  font-weight: 300;
  color: $primary;
  border: 4px solid $primary;
  background-color: rgba($active-color, .2);
  border-radius: 100%;
  width: 94px;
  height: 94px;
  display: flex;
  align-items: center;
  justify-content: center;
  text-align: center;
  margin-bottom: 14px;
}
.support-info-intervenants {
  span {
    &:after {
      content: ", ";
    }
    &:last-child:after {
      display: none;
    }
  }
}
.support-info-tags {
  display: flex;
  flex-wrap: wrap;
}
.support-info-tag {
  color: #fff;
  font-size: 12px;
  background-color: #191919;
  border-radius: 4px;
  padding: 3px 20px;
  margin: 6px 2px 2px;
  position: relative;
  &:before {
    content: "";
    position: absolute;
    left: 0;
    right: 0;
    margin: 0 auto;
    top: -6px;
    width: 0;
    height: 0;
    border-left: 5px solid transparent;
    border-right: 5px solid transparent;
    border-bottom: 6px solid #191919;
  }
} 

.support-info-highlight-block {
  padding: 12px 14px;
  border: 1px solid #ccc;
  background: #efefef;
  border-radius: 6px;
  margin-top: 10px;
  .form-label {
    font-size: 14px;
  }
}