.order-first {
  order: -1;
}

.col-0 {
  @include flex(0 0 auto);
  width: auto;
  padding-right: calc(var(--bs-gutter-x) * .5);
  padding-left: calc(var(--bs-gutter-x) * .5);

}

@include media-breakpoint-up(sm) {
  .col-sm-0 {
    @include flex(0 0 auto);
    width: auto;
    padding-right: calc(var(--bs-gutter-x) * .5);
    padding-left: calc(var(--bs-gutter-x) * .5);
  }
}

@include media-breakpoint-up(md) {
  .col-md-0 {
    @include flex(0 0 auto);
    width: auto;
    padding-right: calc(var(--bs-gutter-x) * .5);
    padding-left: calc(var(--bs-gutter-x) * .5);
  }
}


@include media-breakpoint-up(lg) {
  .col-lg-0 {
    @include flex(0 0 auto);
    width: auto;
    padding-right: calc(var(--bs-gutter-x) * .5);
    padding-left: calc(var(--bs-gutter-x) * .5);
  }
}

@include media-breakpoint-up(xl) {
  .col-xl-0 {
    @include flex(0 0 auto);
    width: auto;
    padding-right: calc(var(--bs-gutter-x) * .5);
    padding-left: calc(var(--bs-gutter-x) * .5);
  }
}

.col-big-btn-center {
  @extend %flexbox;
  @include justify-content(center);
  @include flex(1 0 100%);
  margin-bottom: 14px;

  @include media-breakpoint-down(xs) {
    order: -1;
  }
}
