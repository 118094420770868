.calendar {
	display: flex;
  flex-direction: column;
  align-items: flex-start;
	box-shadow: 0px 3px 6px rgba(0, 0, 0, 0.16);
	padding: 12px 20px;
	background-color: #fff;
}

.vuecal__event {
  &.EMAIL {
    background-color: $theme-email;
  }
  &.RICH_SMS {
    background-color: $theme-oav;
  }
  &.OAV {
    background-color: $theme-oav;
  }
  &.AFFICHE {
    background-color: $theme-affiche;
  }
  &.PLV {
    background-color: $theme-plv;
  }
  &.MARKETING_DIRECT {
    background-color: $theme-marketing;
  }
  &.PAGE_G_FR {
    background-color: $theme-page_g;
  }
  &.RICH_SMS {
    background-color: $theme-rich_sms;
  }
  &.AUTRE {
    background-color: $theme-autre;
  }
}