<template>
  <div
    :class="[
      'field',
      customClass ? ' ' + customClass : '',
      validated && errors ? 'field-error' : '',
    ]"
  >
    <div class="field-block">
      <div class="error-block" v-if="validated && errors">
        <span className="error-message">{{ errors }}</span>
      </div>
      <textarea
        class="form-control"
        :value="modelValue"
        @change="onChange"
        @input="onChange"
        @blur="validated = true"
        :cols="cols"
        :rows="rows"
      />
    </div>
    <span :class="['letters-count', { 'red-text': lettersLeft < 0 }]" v-if="countLetters"
      >{{ lettersLeft }} {{ normalizeword }}</span
    >
    <label class="form-label" v-if="label"> {{ label }}</label>
  </div>
</template>
<script>
import { getError } from "../../utils/validate";
import { normalizePlurielWord } from "../../utils/globalsMethods";
export default {
  emits: ["update:modelValue"],
  props: {
    customClass: {
      type: String,
      default: null,
    },
    label: {
      type: String,
      default: null,
    },
    modelValue: {
      type: String,
      default: null,
    },
    rules: {
      type: Object,
      default: {},
    },
    cols: {
      type: Number,
      default: 30,
    },
    rows: {
      type: Number,
      default: 3,
    },
    maxLength: {
      type: Number,
      default: Number.MAX_VALUE,
    },
    minLength: {
      type: Number,
      default: 0,
    },
    countLetters: {
      type: Boolean,
      default: false,
    },
  },
  data() {
    return {
      validated: false,
    };
  },
  computed: {
    errors() {
      return getError(this.modelValue, { ...this.rules }, {});
    },
    lettersLeft() {
      return this.maxLength - (this.modelValue?.length || 0);
    },
    normalizeword() {
      return normalizePlurielWord("caractère", this.lettersLeft);
    },
  },
  methods: {
    onChange(e) {
      return this.$emit("update:modelValue", e.target.value);
    },
    validate() {
      this.validated = true;
    },
  },
};
</script>
<style scoped>
.letters-count {
  text-align: right;
  color: #006a53;
}
.red-text {
  color: red;
}
</style>
