
.tr-click {
  cursor: pointer;
}

.statut {
  padding: 0.25rem;
  border-radius: 0.25rem;
  color: white;
  white-space: nowrap;
}
.A_ADAPTER {
  background-color: orange;
}
.A_RECONDUIRE {
  background-color: green;
}
.A_ARRETER {
  background-color: red;
}
.NON_CONCERNE {
  background-color: gray;
}
