.pagination-wrapper {
  display: flex;
  justify-content: flex-end;
  align-items: stretch;
  .field {
    margin: 0;
    margin-left: 14px;
    .form-select {
      padding: 0 0 0 12px;
      height: 100%;
      font-size: 15px;
    }
  }
}
.pagination {
  .page-number {
    border: 1px solid #e8e9ec;
    background-color: #ffffff;
    margin: 0 2px;
    padding: 2px 10px;
    border-radius: 3px;
    &.active {
      background-color: #006a53;
      color: #ffffff;
    }
  }
}

#cta {
  margin-top: 30px;
}
