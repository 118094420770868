$switch-true-color: #1e9a38;
$switch-false-color: #bb1717;

.switch {
  display: flex;
  background: #eee;
  justify-content: space-between;
  padding: 2px;
  border-radius: 20px;
  border: 1px solid #aaa;
  .switch-option {
    width: 34px;
    height: 24px;
    display: flex;
    justify-content: center;
    align-items: center;
    position: relative;
    z-index: 2;
    color: #fff;
  }
  .switch-state {
    width: 34px;
    height: 24px;
    position: absolute;
    border-radius: 20px;
    transition: 0.2s ease-in-out;
  }

  &.disabled {
    background-color: rgba($switch-false-color, .1);
    border: 1px solid $switch-false-color;

    .switch-option:nth-child(1) {
      color: darken($switch-false-color, 10%);
      // color: rgba(#000,.6);
    }
    .switch-state {
      background-color: $switch-false-color;
      transform: translateX(100%);
    }
  }
  &.enabled {
    background-color: rgba($switch-true-color, .1);
    border: 1px solid $switch-true-color;
    .switch-option:nth-child(2) {
      color: darken($switch-true-color, 10%);
      // color: rgba(#000,.6);
    }
    .switch-state {
      background-color: $switch-true-color;
    }
  }
}