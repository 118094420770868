body,
#root {
  display: flex;
  flex: 1;
  max-width: 100%;
}

body,
.wrapper {
  min-height: 100vh;
  position: relative;
  // max-height: 100vh;
  // max-width: 100%;
}

.wrapper {
  display: flex;
  flex-direction: row;
  align-items: stretch;
  flex: 1;
  background-color: #f5f4f0;
  main {
    display: flex;
    flex-direction: column;
    position: relative;
    z-index: 1;
    flex: 1;
    // overflow: auto;
    .main-header {
      background-color: $navbar-bkg-color;
      display: flex;
      justify-content: space-between;
      align-items: stretch;
      height: $navbar-height;
      padding: $vertical-layout-padding $horizontal-layout-padding;
      @include media-breakpoint-up(md) {
        padding: $vertical-layout-padding-lg $horizontal-layout-padding-lg;
      }
      position: sticky;
      top: 0;
      z-index: 3;
      .main-header-left,
      .main-header-right {
        display: flex;
        align-items: center;
      }
    }
  }
}

.workspace {
  // overflow: auto;
  flex: 1;
  width: 100%;
  display: flex;
  flex-direction: column;
  padding: $vertical-layout-padding $horizontal-layout-padding;
  @include media-breakpoint-up(md) {
    padding: $vertical-layout-padding-lg $horizontal-layout-padding-lg;
  }
  .full-container {
    display: flex;
    flex-direction: column;
    flex: 1;
    margin: -#{$vertical-layout-padding} -#{$horizontal-layout-padding};
    @include media-breakpoint-up(md) {
      margin: -#{$vertical-layout-padding-lg} -#{$horizontal-layout-padding-lg};
      flex-direction: row;
    }
    .sidebar {
      order: -1;
      @include media-breakpoint-up(md) {
        order: unset;
      }
    }
  }
  h2 {
    margin-bottom: 12px;
    @include media-breakpoint-up(sm) {
      margin-bottom: 24px;
    }
    &.titre-centre {
      text-align: center;
      color: $primary;
      font-size: 24px;
      @include media-breakpoint-up(sm) {
        font-size: 36px;
      }
    }
  }
  .col-title {
    color: $primary;
    text-align: center;
    margin-top: 14px;
    margin-bottom: 28px;
  }
  .content {
    padding: 16px 24px;
    background-color: #fff;
    box-shadow: 0px 3px 6px rgba(0, 0, 0, 0.16);
    + .content,
    + .content-transparent {
      margin-top: 20px;
      @include media-breakpoint-up(lg) {
        margin-top: 40px;
      }
    }
  }
}

.sidebar {
  background-color: #fff;
  @include media-breakpoint-up(md) {
    width: 260px;
  }
  .sidebar-header {
    display: flex;
    flex-direction: column;
    align-items: center;
    padding: 30px 45px 10px;
    border-bottom: 1px solid #e9ebeb;
    .sidebar-title {
      color: $primary;
      text-transform: uppercase;
    }
  }
  .sidebar-content {
    padding: 40px 45px;
    .btn {
      width: 100%;
      margin-bottom: 10px;
    }
  }
}

.block-stats {
  margin-bottom: 30px;
}

.form-footer {
  margin-top: 40px;
}
