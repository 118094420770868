/* open-sans-300 - latin */
@font-face {
  font-family: 'Open Sans';
  font-style: normal;
  font-weight: 300;
  src: local(''),
       url('../webfonts/open-sans/open-sans-v28-latin-300.woff2') format('woff2'), /* Chrome 26+, Opera 23+, Firefox 39+ */
       url('../webfonts/open-sans/open-sans-v28-latin-300.woff') format('woff'); /* Chrome 6+, Firefox 3.6+, IE 9+, Safari 5.1+ */
}
/* open-sans-regular - latin */
@font-face {
  font-family: 'Open Sans';
  font-style: normal;
  font-weight: 400;
  src: local(''),
       url('../webfonts/open-sans/open-sans-v28-latin-regular.woff2') format('woff2'), /* Chrome 26+, Opera 23+, Firefox 39+ */
       url('../webfonts/open-sans/open-sans-v28-latin-regular.woff') format('woff'); /* Chrome 6+, Firefox 3.6+, IE 9+, Safari 5.1+ */
}
/* open-sans-500 - latin */
@font-face {
  font-family: 'Open Sans';
  font-style: normal;
  font-weight: 500;
  src: local(''),
       url('../webfonts/open-sans/open-sans-v28-latin-500.woff2') format('woff2'), /* Chrome 26+, Opera 23+, Firefox 39+ */
       url('../webfonts/open-sans/open-sans-v28-latin-500.woff') format('woff'); /* Chrome 6+, Firefox 3.6+, IE 9+, Safari 5.1+ */
}
/* open-sans-600 - latin */
@font-face {
  font-family: 'Open Sans';
  font-style: normal;
  font-weight: 600;
  src: local(''),
       url('../webfonts/open-sans/open-sans-v28-latin-600.woff2') format('woff2'), /* Chrome 26+, Opera 23+, Firefox 39+ */
       url('../webfonts/open-sans/open-sans-v28-latin-600.woff') format('woff'); /* Chrome 6+, Firefox 3.6+, IE 9+, Safari 5.1+ */
}
/* open-sans-700 - latin */
@font-face {
  font-family: 'Open Sans';
  font-style: normal;
  font-weight: 700;
  src: local(''),
       url('../webfonts/open-sans/open-sans-v28-latin-700.woff2') format('woff2'), /* Chrome 26+, Opera 23+, Firefox 39+ */
       url('../webfonts/open-sans/open-sans-v28-latin-700.woff') format('woff'); /* Chrome 6+, Firefox 3.6+, IE 9+, Safari 5.1+ */
}


@font-face {
  font-family: 'bigoudenn-status';
  src:  url('../webfonts/bigoudenn-status/bigoudenn-status.eot?fj2w7h');
  src:  url('../webfonts/bigoudenn-status/bigoudenn-status.eot?fj2w7h#iefix') format('embedded-opentype'),
    url('../webfonts/bigoudenn-status/bigoudenn-status.ttf?fj2w7h') format('truetype'),
    url('../webfonts/bigoudenn-status/bigoudenn-status.woff?fj2w7h') format('woff'),
    url('../webfonts/bigoudenn-status/bigoudenn-status.svg?fj2w7h#bigoudenn-status') format('svg');
  font-weight: normal;
  font-style: normal;
  font-display: block;
}

[class^="glbicon-"], [class*=" glbicon-"] {
  /* use !important to prevent issues with browser extensions that change fonts */
  font-family: 'bigoudenn-status' !important;
  font-style: normal;
  font-weight: normal;
  font-variant: normal;
  text-transform: none;
  line-height: 1;

  /* Better Font Rendering =========== */
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

.glbicon-bat:before {
  content: "\e900";
}
.glbicon-bat_num:before {
  content: "\e901";
}
.glbicon-bat_print:before {
  content: "\e902";
}
.glbicon-brief:before {
  content: "\e903";
}
.glbicon-ciblage:before {
  content: "\e904";
}
.glbicon-creation:before {
  content: "\e905";
}
.glbicon-integration:before {
  content: "\e906";
}
.glbicon-livraison:before {
  content: "\e907";
}
.glbicon-mentions:before {
  content: "\e908";
}
.glbicon-stat:before {
  content: "\e909";
}
.glbicon-validation:before {
  content: "\e90a";
}
.glbicon-valide:before {
  content: "\e90b";
}

@font-face {
  font-family: 'bigoudenn-menu';
  src:  url('../webfonts/bigoudenn-menu/bigoudenn-menu.eot?ql0p6i');
  src:  url('../webfonts/bigoudenn-menu/bigoudenn-menu.eot?ql0p6i#iefix') format('embedded-opentype'),
    url('../webfonts/bigoudenn-menu/bigoudenn-menu.ttf?ql0p6i') format('truetype'),
    url('../webfonts/bigoudenn-menu/bigoudenn-menu.woff?ql0p6i') format('woff'),
    url('../webfonts/bigoudenn-menu/bigoudenn-menu.svg?ql0p6i#icomoon') format('svg');
  font-weight: normal;
  font-style: normal;
  font-display: block;
}

[class^="menu-icon-"], [class*=" menu-icon-"] {
  /* use !important to prevent issues with browser extensions that change fonts */
  font-family: 'bigoudenn-menu' !important;
  font-style: normal;
  font-weight: normal;
  font-variant: normal;
  text-transform: none;
  line-height: 1;

  /* Better Font Rendering =========== */
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

.menu-icon-accueil:before { content: "\e900"; }
.menu-icon-affiches:before { content: "\e901"; }
.menu-icon-autres:before { content: "\e902"; }
.menu-icon-emails:before { content: "\e903"; }
.menu-icon-marketing:before { content: "\e904"; }
.menu-icon-oavs:before { content: "\e905"; }
.menu-icon-page:before { content: "\e906"; }
.menu-icon-plv:before { content: "\e907"; }
.menu-icon-referentiel:before { content: "\e908"; }
.menu-icon-utilisateurs:before { content: "\e90a"; }
.menu-icon-rich-sms:before {content: "\e909";}