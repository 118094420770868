* {
  box-sizing: border-box;
}

body {
  font-family: "Open Sans", sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

// main.container {
//   padding: 20px;
//   background-color: #fff;
// }

img {
  max-width: 100%;
}
