// Login
$login-box-position: center; // center, fullLeft, fullRight

// If $login-box-position is center
$login-overflow-justify: center;
$login-overflow-alignment: center;
@if $login-box-position== "fullLeft" {
  $login-overflow-justify: flex-start;
  $login-overflow-alignment: stretch;
} @else if $login-box-position== "fullRight" {
  $login-overflow-justify: flex-end;
  $login-overflow-alignment: stretch;
}

// Login Background
$login-background-type: "image"; // image or gradient
$login-overlay-type: "none"; // image, gradient or none
// If Gradient Background
$login-bg-deg: 120deg;
$login-bg-color-1: #ce0f34;
$login-bg-color-2: #931a29;
$login-bg-color-start: 60%;
$login-bg-color-end: 100%;
// If Image Background
$login-bg-picture: url("../images/login-bkg.jpg");
$login-bg-repeat: no-repeat;
$login-bg-size: cover;
$login-bg-position: right center;
// If Gradient Overlay
$login-bg-overlay-deg: 120deg;
$login-bg-overlay-color-1: rgba(#00ff00, 0.4);
$login-bg-overlay-color-start: 0%;
$login-bg-overlay-color-2: rgba(#0000ff, 0.4);
$login-bg-overlay-color-end: 100%;
// If Image Overlay
$login-overlay-picture: url("../images/backgrounds/login-overlay-image.png");
$login-overlay-repeat: no-repeat;
$login-overlay-position: bottom right;
$login-overlay-size: cover;
// Login Logo Mobile
$login-logo-max-width: 380px;
$login-mobile-logo-margin: 0 auto 0;

// Login Logo Desktop
$login-desktop-logo-margin: $login-mobile-logo-margin;

// Login Title
$login-title-size: 0;
// Login Title Separator
$login-separator-height: 1px;
$login-separator-width: 32px;
$login-separator-margin: 20px auto 0;
$login-separator-color: #bdbdbd;
// Login Box
$login-box-direction: column;
$login-box-justify: center;
$login-box-width: 480px;
$login-box-color: inherit;
$login-box-shadow: 10px 0 30px rgba(#000, 0.2);
$login-box-radius: 0;
$login-box-bg-color: rgba(#fff, 0.8);
$login-box-border: none;
$login-box-padding-t: 20px;
$login-box-padding-b: $login-box-padding-t;
$login-box-padding-l: 60px;
$login-box-padding-r: $login-box-padding-l;
$login-box-padding: $login-box-padding-t $login-box-padding-r $login-box-padding-b
  $login-box-padding-l;
$login-box-header-padding: $login-box-padding;
$login-box-content-padding: $login-box-padding-t $login-box-padding-r 50px $login-box-padding-l;
// Login Button Primary
$login-btn-submit-font-size: 16px;
$login-btn-submit-border: none;
$login-btn-submit-bg-color: $primary;
$login-btn-submit-radius: $global-radius;
$login-btn-submit-color: #fff;
$login-btn-submit-padding: 12px 0;
$login-btn-submit-margin: 14px 0 0;

// Login Button Password
$btn-password-alignment: auto;
$btn-password-justify: flex-end;
$btn-password-color: $secondary;

// Login Button Link
$login-btn-link-color: $secondary;
// Login Form Fields
$login-field-margin: 0 0 14px 0;
// Login Form Input Label
$login-label-color: #444444;
$login-label-size: 14px;
$login-label-margin: 0 0 8px 0;
$login-label-icon-size: 20px;
$login-label-icon-margin: 0 6px 0 0;
// Login Form Input
$login-input-size: $form-input-size;
$login-input-color: $text-color;
$login-input-placeholder-color: rgba(#fff, 0.8);
$login-input-bg-color: #F9F9F9;
$login-input-border-width: 1px;
$login-input-border-style: $form-input-border-style;
$login-input-border-color: #B2B2B2;
$login-input-radius: 3px;
$login-input-padding: 20px 12px;
$login-input-error-border-width: $login-input-border-width;
$login-input-error-border-style: $login-input-border-style;
$login-input-error-border-color: $alert-error-color;
// Login Form Input Focus
$login-label-focus-color: $primary;
$login-input-focus-border-width: 1px;
$login-input-focus-border-style: $form-input-border-style;
$login-input-focus-border-color: $primary;
// Login Form Input Error
$login-label-error-color: $alert-error-color;
$login-input-error-icon-size: 20px;
$login-input-error-icon-top: 0;
