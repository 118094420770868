.dropzone {
  form .btn {
    @include flex-direction(column);
    cursor: pointer;
    i {
      font-size: 40px;
    }
    span {
      font-style: italic;
      font-size: 13px;
    }
  }
  .files-list {
    .btn {
      @include align-items(center);
      position: relative;
      span {
        text-align: left;
      }
      i {
        font-size: 40px;
      }
    }
    .remove {
      @extend %flexbox;
      border-radius: 100%;
      i {
        font-size: 18px;
        color: #000;
      }
    }
  }
  &.inline {
    .dropzone-container {
      flex: 1;
    }
    .btn {
      min-height: unset;
      flex-direction: row;
      flex: 1;
      position: relative;
      z-index: 1;
      i {
        font-size: 26px;
      }
      span {
        font-size: 15px;
        margin: 0;
        margin-left: 10px;
      }
    }
  }
  .btn {
    cursor: pointer;
    border-radius: $input-border-radius;
    background-color: #f0f2f8;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    min-height: 300px;
    padding: 10px 0;
    flex-wrap: wrap;
    i {
      opacity: 0.2;
      font-size: 80px;
    }
    span {
      flex: none;
      font-size: 24px;
      color: rgba(#000, 0.3);
      font-weight: bold;
      margin-top: 20px;
    }
  }

  .dropzone-container {
    position: relative;
    input {
      opacity: 0;
      position: absolute;
      width: 100%;
      height: 100%;
      top: 0;
    }
  }
}

.dropzone #image-preview .btn {
  background-color: transparent;
}

.uploaded-picture {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  border: 1px solid #ced4da;
  border-radius: 0.25rem;
  position: relative;
  background-color: #f0f2f8;
  height: 140px;
  img {
    max-height: 100%;
  }
  .remove {
    position: absolute;
    z-index: 2;
    top: -4px;
    right: -4px;
    border: 1px solid #fff;
    border-radius: 100%;
    background: $secondary;
    width: 26px;
    height: 26px;
    display: flex;
    align-items: center;
    justify-content: center;
    text-decoration: none;
    box-shadow: 0 2px 6px rgba(#000, 0.3);
    i {
      color: #fff;
      opacity: 1;
      margin-right: 0;
      font-size: 18px;
    }
  }
}

.uploaded-files-wrapper {
  display: flex;
  flex-direction: column;
  margin-top: 20px;
}

.uploaded-file {
  background-color: rgba(#000,.02);
  border-radius: 4px;
  display: flex;
  align-items: center;
  justify-content: space-between;
  padding: 6px 10px;
  margin-bottom: 6px;
  border: 1px solid rgba(#000,.1);

  &:last-child {
    margin-bottom: 0;
  }
  .uploaded-file-infos {
    flex: 1;
    i {
      margin-right: 10px;
    }
    a {
      color: #000;
      font-size: 15px;
    }
  }
  .remove {
    width: 20px;
    height: 20px;
    display: flex;
    align-items: center;
    justify-content: center;
    background-color: $primary;
    border-radius: 100%;
    i {
      color: #fff;
    }
  }
}