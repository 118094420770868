.confirmation-block {
  a {
    color: $gray-900;
    font-weight: bold;
  }
  i {
    color: #0dbf92;
    font-size: 60px;
    margin-bottom: 30px;
  }
  .icon-confirmation + .confirm-message {
    margin-top: 30px;
  }
  .confirm-message {
    font-size: 18px;
    font-weight: 600;
    color: $gray-900;
    margin-bottom: 30px;
  }
  .confirm-question {
    font-size: 18px;
    font-weight: 700;
  }
  p + .confirm-question {
    margin-top: 60px;
  }
  &.error-message{
    i {
      font-size: 80px;
      margin-bottom: 30px;
      color: #ba2626;
    }
    .confirm-message {
      color: #ba2626;
      font-weight: bold;
    }
  }
}

.confirmation-footer {
  i.arrow.right {
    margin-left: 8px;
  }
}

.bilan-title {
  font-size: 24px;
  color: #707070;
  margin-bottom: 30px;
  font-style: italic;
  strong {
    font-size: 28px;
    font-style: normal;
  }
}

.encours {
  display: flex;
  align-items: center;
  justify-content: center;
  height: 100%;
  font-size: 18px;
  font-weight: 500;
}