$step-bg-style: "solid"; // solid ou transparent
$steps-transparent-padding-b: 40px;

// Steps
$mobile-steps-padding: 0;
$mobile-steps-margin: 0;
$steps-padding-t: 0;
$steps-padding-b: $steps-padding-t;
$steps-padding-l: 30px;
$steps-padding-r: $steps-padding-l;
$steps-padding-vertical: $steps-padding-t;
$steps-padding-horizontal: $steps-padding-l;
$steps-padding: $steps-padding-t $steps-padding-r $steps-padding-b $steps-padding-l;
$steps-margin: 10px 0;

// Step
$step-text-size: 14px;
$step-text-color: #babcbc;
$step-text-weight: 300;
$step-text-align: center;
$step-text-margin: 0;
$step-subtext-size: 12px;
$step-subtext-weight: $step-text-weight;
$step-subtext-style: italic;
$isStepTextWidthConstrained: "no"; // yes or no if yes => width else text nowrap
$step-text-width: 90px;
$step-bg-color: #fff;
$mobile-step-padding-bottom: 8px;

// Step Icon Block
$step-icon-block-width: 60px;
$step-icon-block-height: 50px;
$step-icon-block-radius: 0;
$stepIsBordered: "no";
$step-icon-block-border: 1px solid #fff;

$step-line-color: rgba(#babcbc, 0.5);
$step-line-width: 1px;
// Step Icon
$isIconStyle: "fonticon"; // sprite, number, fonticon
$stepIconFontFamily: "glbicon"; // fontawesome, glbicons
// if is Number or fonticon
$step-icon-size: 36px;
$step-icon-weight: normal;
$step-icon-style: normal;
$step-icon-color: #babcbc;
// if is Sprite
$step-icon-url: url("../images/sprite-nav.png");

// Active Step
$step-active-text-color: $active-color;
$step-active-text-weight: normal;
$step-active-text-style: normal;
$step-active-icon-color: $active-color;
$step-active-line-bg-color: $active-color;
$step-active-bg-color: #fff;

// Achieve Step
$step-achieve-text-color: $primary;
$step-achieve-text-weight: normal;
$step-achieve-text-style: normal;
$step-achieve-icon-color: $primary;
$step-achieve-line-bg-color: $primary;
$step-achieve-bg-color: #fff;
