// Form
$form-separator-border: 1px dashed rgba(#000, 0.2);
$form-separator-margin: 20px 0;

// Field
$form-field-margin: 0 0 14px 0;
// Label
$form-label-size: 14px;
$form-label-weight: 600;
$form-label-margin: 0 0 8px 0;
$form-label-focus-color: lighten($primary, 8%);
// Input, Textarea, Select, etc...
$form-input-size: 15px;
$form-input-lineheight: 20px;
$form-input-radius: $global-radius;
$form-2-input-radius: $global-radius;
$form-input-border-width: 1px;
$form-input-border-style: solid;
$form-input-border-color: #ccc;
$form-input-bg-color: #fff;
$form-input-disabled-bg-color: rgba(#000, 0.1);
$form-input-disabled-color: rgba(#000, 0.5);
$form-input-placeholder-color: rgba(#000, 0.4);
$form-input-padding-vertical: 6px;
$form-input-padding-horizontal: 8px;
$form-input-padding: $form-input-padding-vertical $form-input-padding-horizontal;

// Hover Style Input, Textarea, Select, etc...
$form-input-hover-bg-color: darken($form-input-bg-color, 2%);
$form-input-hover-border-color: darken($form-input-border-color, 10%);

// Focus Style Input, Textarea, Select, etc...
$form-input-focus-border-color: lighten($primary, 8%);

$form-textarea-min-height: 80px;

$form-big-textarea-min-height: 140px;
$form-big-textarea-line-height: 20px;

$inline-form-label-width: 144px; 
$inline-form-label-margin: 14px;
$inline-form-label-pt: 8px;