table.table {
  @include media-breakpoint-up(md) {
    background-color: #fff;
    width: 100%;
    border: 1px solid #b5b6bb;
  }
  thead {
    // @include media-breakpoint-up(md) {
    //   position: sticky;
    //   top: calc(#{$navbar-height} - 1px);
    //   z-index: 2;
    // }
    tr {
      background-color: $primary;
      color: #fff;
      th {
        font-weight: normal;
        font-size: 15px;
        .th-title {
          span {
            margin-right: 10px;
          }
        }
        &.two-btn {
          width: 105px;
        }
        .field-date-interval {
          margin-top: 4px;
        }
        .field {
          margin-bottom: 0;
        }
        input, select {
          margin-top: 4px;
          border-radius: 5px;
          border: none;
          width: 100%;
          padding: 2px 6px;
          font-size: 14px;
        }
        input[type="date"]::-webkit-calendar-picker-indicator {
          position: absolute;
          right: 6px;
          top: 6px;
          margin: 0;
          padding: 0;
          cursor: pointer;
        }
        .btn {
          background-color: #fff;
        }
      }
    }
  }
  > :not(:first-child) {
    border-top: 0;
  }
  tbody {
    tr {
      td {
        font-size: 16px;
        vertical-align: middle;
        color: #707070;
        padding-left: 14px;
        &.image {
          text-align: center;
        }
        ul {
          padding-left: 20px;
        }
      }
      &:nth-child(even) {
        @include media-breakpoint-up(md) {
          td {
            background-color: #f9f9f9;
          }
        }
      }
      &.add-row {
        input {
          background-color: #fff;
        }
      }
      &.add-row {
        td {
          vertical-align: top;
          .field {
            margin-bottom: 0;
          }
        }
      }
    }
  }
  td,
  th {
    &:first-child {
      @include media-breakpoint-up(lg) {
        padding-left: 40px;
      }
    }
    // &:last-child {
    //   @include media-breakpoint-up(md) {
    //     padding-right: 40px;
    //   }
    // }
  }
}

.table-toolbar {
  margin-bottom: 20px;
  .btn-primary {
    color: #666666;
    border: 1px solid #e0e0e0;
    font-size: 16px;
    background-color: transparent;
    padding: 10px 20px;
  }
}

.table-referentiel {
  img {
    @include media-breakpoint-up(md) {
      max-height: 80px;
    }
  }
  .image-type {
    width: 94px;
  }
  .image-marche {
    width: 94px;
  }
}

.table-responsive {
  max-height: 100%;
  overflow: auto;
}

@include media-breakpoint-down(md) {
  table.table {
    background-color: transparent;
    border: none;
    border-radius: 0;
    thead {
      // display: none;
      tr {
        display: flex;
        flex-direction: row;
        flex-wrap: wrap;
        th {
          border: none;
          flex-basis: 50%;
          input {
            width: 100%;
          }
        }
      }
    }
    tr {
      display: flex;
      flex-direction: column;
      margin-bottom: 10px;
      background: #fff;
      border: 1px solid #e0e0e0;
      border-radius: 4px;
    }
    tbody {
      tr {
        td {
          display: flex;
          justify-content: space-between;
          text-align: right;
          border: none;
          color: #333;
          &.image,
          &.buttons {
            &:before {
              display: none;
            }
          }
          &.buttons {
            background-color: #efefef;
            border-top: 1px solid #e0e0e0;
            justify-content: space-evenly;
          }
          &:before {
            content: attr(data-label);
            text-align: left;
            font-weight: 500;
          }
        }
      }
    }
  }
}

.table.table-stats {
   
  th, td {
    font-size: 14px;
    padding: 14px 20px;
    border-bottom: 1px solid #b5b6bb;
    &:first-child {
      padding-left: 20px;
    }
    &:last-child {
      padding-right: 20px;
    }
  }
  tr:last-child {
    td {
      border-bottom: 0;
    }
  }
  tr:nth-child(even) {
    td {
      background-color: #f9f9f9;
    }
  }

  #stats_performance &{
    th{
      border-left: 1px solid #b5b6bb;
      text-align: center;
    }
    td{
      border-left: 1px solid #b5b6bb;
      width: 50%;
    }
  }
}