.footer {
  display: flex;
  justify-content: flex-end;
  flex-wrap: wrap;
}

.mentions-legales {
  padding: 12px 10px;
  margin: 0 -20px -20px;
  background-color: #f5f4f0;
  font-size: 11px;
  text-align: justify;
  line-height: 1.4;
  font-style: italic;
  color: rgba(#000, 0.8);
}
