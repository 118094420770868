.v-select {
  height: auto !important;
  .vs__dropdown-toggle {
    background-color: $input-bg;
    border: $input-border-color solid $input-border-width;
    padding: $input-padding-y $input-padding-x;
    border-radius: $input-border-radius;
    padding-right: 4px;

    .vs__selected {
      border: 0;
      padding: 0;
      margin: 0;
      font-size: $input-font-size;
      line-height: $input-line-height;
    }

    .vs__search {
      border: 0;
      margin: 0;
      padding-left: 0;
      font-size: $input-font-size;
      line-height: $input-line-height;
    }

    .vs__actions {
      padding-top: 0;
      flex: 0 0 auto;

      .vs__clear {
        @extend %flexbox;
        line-height: 1;
      }
    }

    .vs__spinner {
      width: 20px;
      height: 20px;
      border-width: 3px;
      border-color: rgba(#11594b, 0.3);
      border-left-color: #11594b;
    }
  }

  .vs__dropdown-menu {
    background-color: #fff;
    box-shadow: 0 2px 16px -2px rgba(#000, 0.05);
    border: #efefef solid $input-border-width;
    border-radius: $input-border-radius;
    padding: $input-padding-y-lg 0;
    list-style: none;
    position: absolute;
    // width: auto !important;
    // right: 0;
    // left: auto;
    z-index: 999;
    // max-height: 150px;
    overflow: auto;

    li {
      padding: 6px $input-padding-x-lg;
      cursor: pointer;

      &:hover {
        background-color: $primary;
      }
    }
  }

  .vs__dropdown-option--highlight {
    background: $primary;
  }

  &.vs--searching {
    .vs__selected-options {
      input {
        background-color: $input-bg;
      }
    }
  }
}

.error-message+ .field .v-select input {
  background-color: transparent;
  color: $input-color;
}

.vs__selected + .vs__search {
  position: absolute;
  top: 0;
}

.vs--single.vs--open .vs__selected {
  position: static;
}

.vs__clear {
  width: 10px;
}
