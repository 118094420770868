.ck.ck-editor {
  width: 100%;
}

.ck-editor__big {
  .ck-editor {
    .ck-content {
      min-height: 400px;
    }
  }
}