.field {
  @extend %flexbox;
  @include flex-direction(column);
  @include flex-wrap(wrap);
  position: relative;
  margin-bottom: $mobile-question-margin;

  @include media-breakpoint-up(sm) {
    margin-bottom: $question-margin;
  }

  label {
    order: -1;
    margin-bottom: 10px;
    &.label-strong {
      font-weight: bold;
    }
  }
  &.suffixed {
    label {
      order: 1;
      margin-right: 0;
      margin-left: $inline-form-label-margin;
    }
  }
  &.prefixed-icon {
    flex-direction: row;
    align-items: center;
    i {
      width: $form-prefixed-icon-width;
      font-size: 26px;
      margin-right: $inline-form-label-margin;
    }
    .field {
      margin-bottom: 0;
      flex: 1;
    }
  }
  &.inside-prefix {
    flex-direction: row;
    align-items: center;
    border: $input-border-width solid $input-border-color;
    border-radius: $input-border-radius;
    padding: $input-padding-y $input-padding-x;
    flex-wrap: nowrap;
    label {
      width: auto;
      margin-bottom: 0;
      white-space: nowrap;
    }
    input {
      border: none;
      flex: 1;
      padding: 0 0 0 6px;
      background-color: transparent;
    }
  }
  &.center {
    align-items: center;
    label {
      text-align: center;
    }
    .buttons-radio {
      justify-content: center;
    }
  }

  &.inline {
    align-items: flex-start;
    &.center {
      .field-block {
        flex: 0 auto;
      }
    }
    @include media-breakpoint-up(md) {
      flex-direction: row;
      // align-items: center;
      &.center {
        justify-content: center;
        .buttons-radio {
          flex: 0 0 auto;
        }
      }
      input,
      textarea,
      .field-image {
        flex: 2;
      }
      // input {
      //   &[type="datetime-local"] {
      //     width: 0;
      //   }
      // }
      label {
        flex: 1;
        margin-bottom: 0;
        margin-right: $inline-form-label-margin;
        flex: 0 0 auto;
        padding-top: $inline-form-label-pt;
      }
      .v-select,
      select {
        flex: 1;
      }
      &.subfield-no_label {
        margin-left: calc(#{$inline-form-label-width} + #{$inline-form-label-margin});
      }
      &.inline-right {
        label {
          text-align: right;
          width: $inline-form-label-width;
        }
      }
    }
  }
  &.field-checkbox {
    height: 38px;
    justify-content: center;
  }
  &.field-validated {
    margin-bottom: 40px;
  }
}

input[type="date"]::-webkit-calendar-picker-indicator {
  // position: relative;
  // right: 6px;
  // top: 10px;
  // margin: 0;
  // padding: 0;
  cursor: pointer;
}

.form-email {
  .form-email-infos {
    .field {
      &.jours {
        input {
          flex: 0 0 auto;
          width: 60px;
        }
        label {
          margin-left: $inline-form-label-margin;
          flex: 0;
        }
      }
    }
  }

  .form-email-intervenants {
    .field {
      .v-select {
        min-width: 220px;
      }
    }
  }
}

.checkbox-entete {
  text-align: right;
}

.form-col-title {
  text-align: center;
  color: $primary;
  font-weight: 600;
  text-transform: uppercase;
  padding-bottom: 4px;
  margin-bottom: 16px;
  border-bottom: 1px solid #f2f2f2;
}
.field + .form-col-title {
  margin-top: 20px;
}

.preview-email {
  margin-bottom: 10px;
  border: 1px solid #ced4da;
  padding: 0 24px;
}

.field-block {
  display: flex;
  flex-direction: column;
  flex: 1;
}
.date .field-block {
  flex: 0 0 150px;
  position: relative;
}
.form-value {
  padding-top: $inline-form-label-pt;
  flex: 1;
}
.input-jours input {
  width: 80px !important;
  text-align: right;
  padding-right: 0;
}
