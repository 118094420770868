@mixin error-block($color) {
  .error-message {
    font-size: 12px;
    color: $color;
    font-weight: 600;
    &:before {
      content: "\F057";
      font-size: 14px;
      margin-right: 6px;
      width: auto;
      font-family: "Font Awesome 6 Pro";
      font-weight: 900;
    }
  }
  + input,
  + select,
  + .v-select .vs__dropdown-toggle,
  + .checkbox {
    border-color: $color !important;
    background-color: rgba($color, 0.05) !important;
  }
  + .buttons-radio .btn-radio,
  + .dropzone-container .btn {
    color: $color !important;
    border: 1px solid $color !important;
    background-color: rgba($color, 0.05) !important;
  }
  + i[class^="glbicon-"] {
    color: $color;
    + .inside-prefix {
      border-color: $color;
      background-color: rgba($color, 0.05) !important;
    }
  }
}
.field-error {
  label {
    color: $alert-error-color !important;
  }
  .dropzone-container .btn {
    color: $alert-error-color !important;
    border: 1px solid $alert-error-color !important;
    background-color: rgba($alert-error-color, 0.05) !important;
  }
}

.error-block {
  order: 1;
  flex: 1 0 auto;
  @include error-block($alert-error-color);
  .error-message {
    margin-top: 3px;
    padding: 0 2px;
  }
  &.error-date {
    font-size: 13px;
    padding: 3px 5px 0;
  }
}

.field-checkbox {
  .error-message {
    font-size: 0;
    &:before {
      margin-right: 0;
      position: absolute;
      bottom: -6px;
      left: -8px;
    }
  }
}
.warning-block {
  @include error-block($alert-warning-color);
  .error-message {
    &:before {
      content: "\F071";
    }
  }
}

// .inline {
//   .error-block {
//     @include media-breakpoint-up(md) {
//       flex: 1 0 100%;
//       width: 0;
//       .error-message {
//         margin-left: calc(#{$inline-form-label-width} + #{$inline-form-label-margin});
//       }
//     }
//   }
// }
.prefixed-icon {
  .error-block {
    @include media-breakpoint-up(md) {
      width: 0;
      flex: 1 0 100%;
      .error-message {
        margin-left: calc(#{$form-prefixed-icon-width} + #{$inline-form-label-margin});
      }
    }
  }
}
