// Header
$header-bg: url("../images/header-bkg.jpg");
$vertical-header-padding: $vertical-layout-padding;
$horizontal-header-padding: $horizontal-layout-padding;
$vertical-header-padding-lg: $vertical-layout-padding-lg;
$horizontal-header-padding-lg: $horizontal-layout-padding-lg;
$vertical-header-padding-xl: $vertical-layout-padding-xl;
$horizontal-header-padding-xl: $horizontal-layout-padding-xl;
// Header title
$header-title-bg: rgba(#fff, .8);
$header-title-size: 19px;
$header-title-size-lg: 36px;
$header-title-color: $primary;
$header-title-padding: 0;
$header-title-padding-lg: 0;
// Header title icon
$header-title-icon-mr: 10px;
$header-title-icon-size: 30px;
$header-title-icon-mr-lg: 24px;
$header-title-icon-size-lg: 50px;

$btn-menu-color: $primary;
$btn-menu-bg-color: $header-title-bg;
$btn-menu-padding: 0;
$btn-menu-width: 34px;
$btn-menu-height: 32px;
$btn-menu-icon-size: 20px;

// Subheader
$mobile-subheader-margin: 20px;
$subheader-margin: 36px;

// Subheader Title

$subheader-title-color: #444444;
$subheader-title-size: 24px;
$subheader-title-weight: normal;
$subheader-title-margin: 38px 0 20px;

$isTitleHorizontalLines: 'yes'; // yes or no
$isTitleHorizontalLinesBordered: 'yes'; // yes or no line with border
$subheader-title-lines-height: 2px;
$subheader-title-lines-margin: 40px;
$subheader-title-lines-flex-grow: 1; // 1 => Toute la place || 0 => Taille du flex basis
$subheader-title-lines-flex-basis: 0; // 0 => Pour grow 1  || XXpx => Taille exacte
$subheader-title-lines-color: #d1d1cf;
$subheader-title-lines-border: 1px solid #fff;
